import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://techcrunch.com/2016/05/17/affirm-is-partnering-with-expedia-and-eventbrite-so-you-can-pay-for-experiences-over-time/"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "300px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAMF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAddYRNQv/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAEDERIC/9oACAEBAAEFAupaFKKVMyikZR//xAAXEQEAAwAAAAAAAAAAAAAAAAAAAhEh/9oACAEDAQE/Aak1/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAgMBAAAAAAAAAAAAAAAAADEBECER/9oACAEBAAY/AsjotpCEf//EAB0QAAICAQUAAAAAAAAAAAAAAAABEWGRMUFxgfD/2gAIAQEAAT8hmNTFr7o2G7oIpcrkpYK2Clg//9oADAMBAAIAAwAAABA8z//EABgRAAMBAQAAAAAAAAAAAAAAAAABESFR/9oACAEDAQE/ENLRLs//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAdEAEAAgICAwAAAAAAAAAAAAABESEAMUGRUXHR/9oACAEBAAE/ECVkwmjS1ijSFRiVALfLkVJ4QHCSNe8oCsIKav694EyT0z2wHr0z/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "affirmlogo-green_lg1_30807",
            "title": "affirmlogo-green_lg1_30807",
            "src": "/static/0dc980684bce1054c5aa0e72e53acda0/8fc94/affirmlogo-green_lg1_30807-300x173.jpg",
            "srcSet": ["/static/0dc980684bce1054c5aa0e72e53acda0/8675c/affirmlogo-green_lg1_30807-300x173.jpg 100w", "/static/0dc980684bce1054c5aa0e72e53acda0/3cc80/affirmlogo-green_lg1_30807-300x173.jpg 200w", "/static/0dc980684bce1054c5aa0e72e53acda0/8fc94/affirmlogo-green_lg1_30807-300x173.jpg 300w"],
            "sizes": "(max-width: 300px) 100vw, 300px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`Affirm has made it no secret that its success will hinge on its ability to offer customers a wide range of retail partners where they can frequently use Affirm to pay.`}</p>
    <p>{`While the lending startup originally launched with niche partners like Casper mattresses and Boosted Boards, they have expanded partnerships to over 700 retail merchants with the hopes of tempting a more diverse range of shoppers to try the service.`}</p>
    <p>{`But today at Shoptalk Affirm announced they are partnering with Expedia and Eventbrite to offer 3-, 6-, or 12-month payment plans to customers buying intangible “experiences” like a vacation or concert ticket.`}</p>
    <p>{`On the surface, the benefits for both sides are clear. Affirm gets a large, pre-existing customer base with whom it can offer financing, and Expedia and Eventbrite get to offer their customers a new payment option, which Affirm says typically leads to increased sales performance by retailers.`}</p>
    <p>{`But are one-time experiences really something consumers should finance? The answer is probably that it depends on when the actual experience is in relation to when you made the purchase.`}</p>
    <p>{`For instance, it would be nice to pay over six months `}<em parentName="p">{`before`}</em>{` you fly to that exotic country you’ve always been dying to visit. But on the other hand, who wants to be stuck paying for six months `}<em parentName="p">{`after`}</em>{` you’ve experienced the trip?`}</p>
    <p>{`Financing experiences also raises the question of whether or not shoppers will still repay Affirm after they already went on a vacation. With a tangible good, you continue to gain utility every day for months or even years after you’ve repaid the company. But with a vacation, shoppers may be more likely to leave Affirm on the hook for the balance of the trip when they are still paying a year after the experience they now barely remember.`}</p>
    <p>{`The company’s response to this is that they will be treating travel and ticketing the same way as material goods, and still will use “thousands of data points” to assess a consumer’s ability to repay. And, if repayment trends do change, they will “adjust their models accordingly to ensure they approve consumers in ways that maximize repayment rates”.`}</p>
    <p>{`As we’ve discussed before, high-interest rate financing plans like the ones offered by Affirm can be a sticky situation.  On one hand, there is a risk of customers using the service irresponsibly to finance nonessential items that they can’t afford. But, if used responsibly,  Affirm can empower consumers to stretch their dollar – so they don’t have to settle for goods or experiences that they don’t really want, but are the only thing they can immediately afford.`}</p>
    <p><a parentName="p" {...{
        "href": "http://techcrunch.com/2016/05/17/affirm-is-partnering-with-expedia-and-eventbrite-so-you-can-pay-for-experiences-over-time/"
      }}>{`TechCrunch`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      